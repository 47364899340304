import React from "react"
import { Link } from "gatsby"

import Socials from "../Socials"
import LogoIcon from "../../assets/fastenerfinder-logo.svg"

const Header = () => {
  return (
    <div className="flex flex-wrap py-[10px] px-[5%] items-center justify-center md:justify-between ">
      <div className="flex justify-start">
        <Link to="/">
          <span className="sr-only">Fastener Finder</span>
          <LogoIcon className="w-[240px] my-[10px]" />
        </Link>
      </div>

      <Socials />

      <div className="my-[10px] flex justify-end">
        <a
          href="https://bissettfasteners.ca/"
          target="_blank"
          className="no-underline font-orbitron text-[2rem] font-bold tracking-[1.5px] "
        >
          BISSETT
        </a>
      </div>
    </div>
  )
}

export default Header
