import React from "react"

import classNames from "classnames"

// import app components
import Header from "./Header"
import heroImage from "../../images/second-image.webp"
import { StaticImage } from "gatsby-plugin-image"

const Layout = props => {
  return (
    <>
      <div className="bg-black">
        <Header />
        <div className="flex items-center justify-center">
          <StaticImage
            src="../../images/second-image.webp"
            alt="l"
            alt="Bisset Tools"
            className={classNames(
              "animate-fade-in",
              "mx-auto",
              "border-top border-bottom border-4 border-white transition-opacity border-l-0 border-r-0 max-h-[500px] max-w-[700px]  center"
            )}
          />
        </div>

        {/* <img
          src={heroImage}
          alt="Bisset Tools"
          className={classNames(
            "animate-fade-in",
            "mx-auto",
            "border-top border-bottom border-4 border-white transition-opacity border-l-0 border-r-0 max-h-[500px]"
          )}
        /> */}

        <main>{props?.children}</main>
      </div>
    </>
  )
}

export default Layout
