exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-brands-tsx": () => import("./../../../src/pages/brands.tsx" /* webpackChunkName: "component---src-pages-brands-tsx" */),
  "component---src-pages-fr-brands-tsx": () => import("./../../../src/pages/fr/brands.tsx" /* webpackChunkName: "component---src-pages-fr-brands-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-brand-tsx": () => import("./../../../src/templates/brand.tsx" /* webpackChunkName: "component---src-templates-brand-tsx" */),
  "component---src-templates-model-tsx": () => import("./../../../src/templates/model.tsx" /* webpackChunkName: "component---src-templates-model-tsx" */)
}

